import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import generateBase32Secret from './secret';
import otp from './otp';        

function Gen() {
    const searchParams = new URLSearchParams(document.location.search)
    var secret = generateBase32Secret();
      const username = searchParams.get('username')
      var opturl = otp(secret, username);
        return (
        <div className="App">
          <header className="App-header">
            <h1>PeerBrain</h1>
            <h2>MFA setup for {username}</h2>
            <p>
              {secret}
            </p>
            <QRCode value={opturl} renderAs="svg" />
          </header>
        </div>
      );
  }


export default Gen;  